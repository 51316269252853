import styled from "styled-components";

export const Screen = styled.div`
  background-color: "rgba(0, 0, 0, 0.96)";
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
  background-attachment: fixed;
  max-width: 100vw;
`;

export const ScrollBar = styled.div`
  overflow-y: auto;
  height: 100%;
  position: absolute;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 1.5vh;
  width: 1.5vw;
`;


// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  position: center;
`;

export const  TextTitle = styled.p`
text-transform: uppercase;
-webkit-touch-callout: none; /* iOS Safari */
-webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
width: 100%;
font-size: 3vw;
font-family: font;
@media (max-width: 700px) {
  font-size: 5.2vw;
  marginBottom:0px,
}
@media (max-width: 400px) {
  font-size: 6.2vw;
  marginBottom:0px;

}
font-weight: 500;
line-height: 1;
color:white;
background: rgba(0,0,0,0);
  text-align: center;
  padding:7;
  margin-top: 10px;
text-shadow:
  0 0 7px #ffc0eb,
  0 0 10px #ffc0eb,
  0 0 21px #ffc0eb,
  0 0 42px #ff77f8,
  0 0 82px #ff77f8,
  0 0 20px #ff77f8,
  0 0 80px #ff77f8,
  0 0 60px #ff77f8 ;
  @keyframes flicker {
    0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
      //text-shadow: none;
      opacity: 1;
      
    }
    20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
      opacity: 0.6;
    }
  }
`;

export const TextSubTitle = styled.p`
text-transform: uppercase;
width: 100%;
font-size: 2vw;
font-family: font;
@media (max-width: 600px) {
  font-size: 4vw;
}
  color: "var(--accent-text)";
  text-align: justify;  
  padding: 0;
  background: -webkit-linear-gradient(deeppink, pink, white );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
`;


export const TextSubTitleBio = styled.p`
text-transform: uppercase;
width: 100%;
font-size: 1.2vw;
font-family: font;
text-align: justify;
@media (max-width: 700px) {
  font-size: 3vw;
}
  color: "var(--accent-text)";
  padding: 0;
  background: -webkit-linear-gradient(#66ffff, #66ffcc, white );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
`;

export const TextDescription = styled.p`
text-transform: uppercase;
line-height: 1.1;
width: 100%;
font-size: 16;
font-family: font;
text-align: stretched;
align-self: center;
  @media (max-width: 600px) {
    width: 70%;
    font-size: 14px;
  }
  @media (max-width: 1000px) {
    width: 70%;
    font-size: 15px;
  }
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
`;

