import styled from "styled-components";
export const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;


export const ResponsiveWrapper = styled.div`
  display: inline-flex;
  max-width: 100%;
  flex: 1;
  flex-direction: row;
  justify-content: stretched;
  align-items: start;
  width: 100%;
`;
export const ResponsiveWrapperBio = styled.div`
width: 50%;
max-width: 100%;
margin-top: 10vh;
margin-bottom: 10vh;
  display: inline-flex;
  flex: 1;
  flex-direction: row;
  justify-content: stretched;
  align-items: start;
  @media (max-width: 1000px) {
  width: 70%;
  margin-top: 10vh;
  margin-bottom: 10vh;
  }
`;

export const StyledImg = styled.img`
  box-shadow: 0px 3px 15px 4px rgba(0, 0, 0, 0.7);
  padding: 0;
  overflow: hidden;
  float: none;
  border: 0px solid var(--secondary);
  background-color: var(--accent);
  max-width: 100vw;
  height: auto;
  position: center;
  transition: width 0.5s;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
`;

export const BioImg = styled.img`
  box-shadow: 0px 3px 15px 4px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary);
  background-color: var(--accent);
  vertical-align: middle;
  height: auto;
  object-fit: cover;
  width: 50%;
  @media (max-width: 700px) {
    display: none;
  }

  transition: width 0.5s;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
`;
