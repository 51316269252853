import React, { useEffect} from "react";
import { NavBar } from "./navbar";
import * as s from "./styles/globalStyles";
import {ResponsiveWrapper} from "./components";
import WebFont from 'webfontloader';

function Aware() {  
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  WebFont.load({
    custom: {
      families: ['font'],
    },
  });
return (
<s.Screen>
 <s.ScrollBar>
      <s.Container
        flex={1}
        ai={"relative"}
        style={{
          padding: 0,
          width:"100vw",
          height:"100%",
          margin: 0,
          overflowX: "hidden",
          overflowY: "scroll",
          backgroundColor: "rgba(0, 0, 0, 0.96)"}}
        >
<NavBar/>

        <ResponsiveWrapper felx={1} style={{marginTop: 5,padding: 0, backgroundColor: "rgba(0, 0, 0, 0.96)"}} >
        <video id="wall-video" autoPlay loop muted poster="config/images/forest.webp">
        <source src={require('./videos/forest.webm')} type="video/webm"/>
        </video>
        <video id="wall-video" autoPlay loop muted poster="config/images/flows.webp">
        <source src={require('./videos/flows.webm')} type="video/webm"/>
        </video>
        <video id="wall-video" autoPlay loop muted poster="config/images/testproject.webp">
        <source src={require('./videos/testproject.webm')} type="video/webm"/>
        </video>
        </ResponsiveWrapper>
        <ResponsiveWrapper felx={1} style={{marginBottom:5,padding: 0, backgroundColor: "rgba(0, 0, 0, 0.96)"}} >
        <video id="wall-video" autoPlay loop muted poster="config/images/saimaa.webp">
        <source src={require('./videos/saimaa.webm')} type="video/webm"/>
        </video>
        <video id="wall-video" autoPlay loop muted poster="config/images/stars.webp" >
        <source src={require('./videos/stars.webm')} type="video/webm"/>
        </video>
        <video id="wall-video" autoPlay loop muted poster="config/images/tilsala.webp">
        <source src={require('./videos/tilsala.webm')} type="video/webm"/>
        </video>
          </ResponsiveWrapper>
          <s.TextDescription
                  style={{marginTop:"50px",textAlign:"center", fontSize:13,color:"white", marginBottom:10, width:"100%"}}>
                  © 2023 Threat Kreator All rights reserved
                  </s.TextDescription>
            </s.Container>
              </s.ScrollBar>
            </s.Screen>
  );
}
export default Aware
;