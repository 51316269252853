import React from "react";
import {Link} from 'react-router-dom';
import * as s from "./styles/globalStyles";

export const NavBar = () => {
    return(
<s.Container style={{
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    alignItems: "center",
    boxShadow: "0 0 0 6px #000000, 0 0 0 5px #000000, 0 0 0 6px #000000, 3px 4px 10px 3px rgb(255, 80, 215)",
    position: "relative",
    border: "1px rgb(255, 0, 238)",
    borderStyle:"none none solid"}}>
    <Link to="/" style={{ textDecoration:"none"}}>
      <s.TextTitle 
      style={{
        padding: 0,
        textAlign:"center",
        marginTop:"15px",
        marginBottom:"4px",
        animation: "flicker 4s infinite",
        color: "#fff2fa"
      }} 
      > THREAT KREATOR'S ART CAVE 
      </s.TextTitle>
      </Link>
      <div className="dropdown">
     <button className="button"> main menu </button>
     <div className="content">
      <a href="/">Gallery</a>
      <a href="/about">Story</a>
      <a href="/artcave">Art Cave</a>
      <a href="/animations">Animations</a>
      <a href="https://www.instagram.com/threat_kreator/" aria-label="Instagram">Contact</a>
     </div>
     </div>       
      </s.Container>
          );
        }