import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Animations from "./Aware";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import About from "./Bio";
import Artcave from "./Artcave";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
      <Routes>
    <Route exact path='/' element={<App/>} />
    <Route exact path='/About' element={<About/>} />
    <Route exact path='/Animations' element={<Animations/>} />
    <Route exact path='/artcave' element={<Artcave/>} />
    </Routes>
    </Router> 
);
